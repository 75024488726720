import revive_payload_client_4sVQNw7RlN from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_2ruDq4uEtU from "/opt/buildhome/repo/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_yVLowv6hDl from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_Jozdw60ZsE from "/opt/buildhome/repo/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_jg3TSEXw60 from "/opt/buildhome/repo/node_modules/@hebilicious/vue-query-nuxt/dist/runtime/plugin.mjs";
import auth_redirect_bWtzICY6fh from "/opt/buildhome/repo/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.js";
import plugin_client_4F1kZh3YAB from "/opt/buildhome/repo/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import slideovers_X7whXyrIWR from "/opt/buildhome/repo/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/opt/buildhome/repo/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/opt/buildhome/repo/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/opt/buildhome/repo/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/opt/buildhome/repo/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import firebase_Wjz9XrZo01 from "/opt/buildhome/repo/plugins/firebase.ts";
import vue_mountable_fz1MuxJRc2 from "/opt/buildhome/repo/plugins/vue-mountable.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  supabase_client_2ruDq4uEtU,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_Jozdw60ZsE,
  plugin_jg3TSEXw60,
  auth_redirect_bWtzICY6fh,
  plugin_client_4F1kZh3YAB,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  firebase_Wjz9XrZo01,
  vue_mountable_fz1MuxJRc2
]